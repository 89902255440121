import React from 'react';
import {Container} from "react-bootstrap";

const DefaultPage = ({ children }) => {
    return (
        <Container className="mt-0 pt-2 pb-5 align-text-top bg-white" fluid={"md"}>
            {children}
        </Container>
    );
};

export default DefaultPage;