import React from 'react';
import {Link, useParams} from "react-router-dom";
import axios from "axios";
import {Badge, Carousel, Col, Container, Image, ListGroup, ListGroupItem, Row, Spinner} from "react-bootstrap";
import ErrorPage from "./ErrorPage";
import DefaultPage from "../components/default-page";

const PortfolioItem = () => {
    let {id} = useParams()
    const [item, setItem] = React.useState([])
    const [loading, setLoading] = React.useState(true);
    let languages = []
    let categories = []
    let github;
    let references;
    React.useEffect(()=> {
        axios
            .get("https://wessel.digital/api/portfolio/"+id)
            .then((r) => {
                setItem(r.data)
                setLoading(false)
            })
            .catch(e => {
                console.log(e)
                setLoading(false)
            })
    }, [])

    if (loading === true && item.length === 0) {
        return (
            <DefaultPage>
                <div className="header">
                    <Spinner animation="border" role="status">
                        <h1 className="visually-hidden">Loading...</h1>
                    </Spinner>
                </div>
            </DefaultPage>
        )
    }else if(loading === false && item.length === 0){
        return (
            <ErrorPage></ErrorPage>
        )
    }else {
        if(item.languages.length > 0){
            const badges = item.languages.map(function (language) {
                return(
                            <Badge className="bg-info text-white m-1" key={language}>{language}</Badge>)
            })

            languages = <ListGroupItem key="languages" className="text-center"><h4 className="text-body-secondary">{item.languages.length>1 ? "Talen" : "Taal"}</h4>{badges}</ListGroupItem>
        }

        if(item.categories.length > 0){

            const badges = item.categories.map(function (category) {
                return(
                    <Badge className="bg-secondary text-white m-1" key={category}>{category}</Badge>)}
            )
            categories = <ListGroupItem key="categories" className="text-center">{badges}</ListGroupItem>

        }

        if(item.github !== null && item.github !== ""){
            github = <ListGroupItem key="github" className="text-center"><Image src="../media/github-mark.png" style={{height:"2rem"}} className="m-1"></Image><Link style={{ textDecoration: 'none' }} target="_blank" to={item.github}>Bekijk de code op Github</Link></ListGroupItem>
        }

        if(item.references.length > 0){
            const links = item.references.map(function (reference) {
                return(
                    <Link to={reference.url} target="_blank">{reference.description}</Link>)}
            )
            references = <ListGroupItem key="references" className="text-center">{links}</ListGroupItem>
        }

        return (
            <DefaultPage>
                <Container fluid={"lg"} className="m-0">
                    <h1 className="header pb-2 display-4">{item.title}</h1>
                    <Carousel className="text-center justify-content-center border border-2 shadow bg-light bg-opacity-25 rounded-2" variant="dark">
                        {item.images.map(function (image) {
                            console.log(image)
                            return(
                                <Carousel.Item>
                                    <Image src={"../media/"+image} className="object-fit-scale mw-100" style={{marginLeft: "auto", marginRight: "auto",  justifyContent: "center", height: "50vh"}}></Image>
                                </Carousel.Item>
                            )
                        })}
                    </Carousel>
                    <h4 className="header text-body-secondary pt-3">{item.subtitle}</h4>
                    <Row>
                        <Col xs={12} lg={3} className="order-lg-1">
                            <Row>
                                <ListGroup className="text-lg-start text-center">
                                    {languages}
                                    {categories}
                                    {github}
                                    {references}
                                </ListGroup>
                            </Row>
                        </Col>
                        <Col xs={12} lg={9}>
                            <p className="text-start">{item.text.split('\n').map(str => <p>{str}</p>)}</p>
                        </Col>
                    </Row>
                </Container>
            </DefaultPage>
        )
    }
}

export default PortfolioItem;