import React from "react";
import {
    Badge,
    Card,
    CardHeader,
    CardImg, Col,
    Container,
    ListGroup,
    ListGroupItem, Row, Tab, Tabs,
} from "react-bootstrap";
import axios from "axios";
import {Link} from "react-router-dom";
import DefaultPage from "../components/default-page";

const Portfolio = () => {
    const [items, setItems] = React.useState([])
    React.useEffect(()=> {
        axios
            .get("https://wessel.digital/api/portfolio")
            .then((r) => {
                console.log(r.data)
                setItems(r.data)
            })
            .catch(e => {
                console.log(e)
            })
    },[])

    return (

        <DefaultPage>
            <h2 className="header text-primary display-5">Portfolio</h2>
            <Tabs
                defaultActiveKey="Software"
                id="uncontrolled-tab-example"
                justify
            >
                <Tab tabClassName="text-primary" eventKey="Software" title="Software Development">
                    <Container className="text-center gap-4 mt-4" fluid>
                        <Row xs={1} md={2} lg={3} className="g-4">
                        {items.map(function (item) {
                            if (item.categorie === "software") {
                                return <Col key={item.id}>
                                    <PortfolioItem
                                        title={item.title}
                                        subtitle={item.subtitle}
                                        thumbnail={item.thumbnail}
                                        text={item.text}
                                        languages={item.languages}
                                        categories={item.categories}>
                                    </PortfolioItem>
                                </Col>
                            }
                        })}
                        </Row>
                    </Container>
                </Tab>
                <Tab tabClassName="text-primary" eventKey="3dprinting" title="Andere projecten">
                    <Container className="text-center gap-4 mt-4" fluid>
                        <Row xs={1} md={2} lg={3} className="g-4">
                            {items.map(function (item) {
                                if (item.categorie === "other") {
                                    return (<Col key={item.id}>
                                        <PortfolioItem
                                            title={item.title}
                                            subtitle={item.subtitle}
                                            thumbnail={item.thumbnail}
                                            text={item.text}
                                            languages={item.languages}
                                            categories={item.categories}>
                                        </PortfolioItem>
                                    </Col>)
                                }
                            })}
                        </Row>
                    </Container>
                </Tab>
            </Tabs>
        </DefaultPage>
    )
}

function PortfolioItem({title, subtitle, thumbnail, text, languages, categories}) {
    return (
            <Link to={`/portfolio/${title}`} style={{ textDecoration: 'none' }}>
                <Card className="text-center shadow">
                    <CardImg src={thumbnail} alt={"cover image for "+title} className="card-img-top object-fit-cover bg-primary" height="250rem" variant="top"></CardImg>
                    <CardHeader className="bg-light text-primary" as="h4">{title}</CardHeader>
                    <ListGroup className="text-lg-start" variant="flush">
                        <ListGroupItem className="bg-light" key={title}>
                            {languages.map(language=><Badge className="bg-info text-white m-1" key={language}>{language}</Badge>)}
                            {categories.map(category=><Badge className="bg-secondary text-white m-1" key={category}>{category}</Badge>)}
                        </ListGroupItem>
                    </ListGroup>
                </Card>
            </Link>
    )
}
export default Portfolio;
