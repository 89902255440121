import React from "react";
import {Navbar, Container, Nav} from "react-bootstrap";

const NavigationBar = () => {
    return (
        <Container className="sticky-top p-0" fluid>
        <Navbar collapseOnSelect expand="lg" className="bg-primary shadow p-2" data-bs-theme="dark">
            <Container fluid={"md"} className="">
                    <Navbar.Brand href="/" className="p-0 m-0"><h2>Wessel Digital</h2></Navbar.Brand>
                    <Navbar.Toggle />
                    <Navbar.Collapse className="justify-content-end">
                        <Nav className="text-center">
                            <Nav.Link href="/portfolio">Portfolio</Nav.Link>
                            <Nav.Link disabled href="/photography">Fotografie (WIP)</Nav.Link>
                        </Nav>
                    </Navbar.Collapse>
            </Container>
        </Navbar>
        </Container>
    )
}

export default NavigationBar