import React from "react";
import DefaultPage from "../components/default-page";

const Photography = () => {
    return (
        <DefaultPage>
            <h2 className="header text-primary">Photography</h2>
            <h3 className="header">(coming soon)</h3>
        </DefaultPage>
    )
}

export default Photography;