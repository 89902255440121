import React from 'react';
import './App.scss';
import {BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import Home from "./pages";
import Portfolio from "./pages/portfolio";
import Photography from "./pages/photography";
import ErrorPage from "./pages/ErrorPage";
import PortfolioItem from "./pages/portfolio-item";
import {Container} from "react-bootstrap";
import NavigationBar from "./components/navigation";

function App() {
    return (

        <Container className="bg-white min-vh-100 p-0" fluid>
            <NavigationBar></NavigationBar>
                <Router>
                    <Routes>
                        <Route path='/' element={<Home/>} errorElement={<ErrorPage/>}/>

                        <Route path='/portfolio' element={<Portfolio/>}/>
                        <Route path='/portfolio/:id' element={<PortfolioItem/>}/>
                        <Route/>

                        <Route path='/photography' element={<Photography/>}/>
                    </Routes>
                </Router>
            {/*<DefaultFooter></DefaultFooter>*/}
        </Container>
    );
}

export default App;

//TODO: lagere resolutie foto(s)
//TODO: portfolio item page
