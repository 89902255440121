import React from 'react';
import {Image, Card, CardText, Carousel, Col, Container, Row, CardImg} from "react-bootstrap";
import {Link} from "react-router-dom";

const Home = () => {
    const carouselInterval = 2000

    return (
        <Container fluid className="text-center pt-3 pb-3 bg-white min-vh-100" style={{fontFamily: 'calibri'}}>
            <Container fluid={"lg"} className="p-3 pt-0 pb-2">
            <Row xs={1} lg={2} className="g-2 align-items-center">
                <Col className="">
                    <Row className="g-0 justify-content-center">
                        <h1 className="w-auto text-end text-primary display-2 text-muted m-1">Ik ben</h1>
                        <h1 className="w-auto text-start text-primary display-2 fw-bold m-1">Wessel</h1>
                    </Row>
                    <Row xs={2} md={2} className="g-0 justify-content-center">
                        <h2 className="text-primary display-4 text-end fw-medium" style={{maxWidth: "40%", paddingRight: "10px"}}>Ik ben</h2>
                        <Carousel controls={false} className="m-0" indicators={false} pause={false}>
                            <Carousel.Item interval={carouselInterval}>
                                <h2 className="text-start text-primary display-4 text-muted">Techneut</h2>
                            </Carousel.Item>
                            <Carousel.Item interval={carouselInterval}>
                                <h2 className="text-start text-primary display-4 text-muted">Developer</h2>
                            </Carousel.Item>
                            <Carousel.Item interval={carouselInterval}>
                                <h2 className="text-start text-primary display-4 text-muted">Maker</h2>
                            </Carousel.Item>
                            <Carousel.Item interval={carouselInterval}>
                                <h2 className="text-start text-primary display-4 text-muted">Creatief</h2>
                            </Carousel.Item>
                        </Carousel>
                    </Row>
                </Col>
                <Col className="justify-content-center">
                    <Image className="align-self-center object-fit-cover" style={{height: "300px", width: "auto"}} src="./media/portrait.jpg" fluid roundedCircle thumbnail />
                </Col>
            </Row>
            </Container>

            <Container className="p-3">
                <Row className="justify-content-center">
                    <Card className="w-auto border-0 text-center">
                        <Link to="https://linkedin.com/in/wessel-bakker-549887154/" target="_blank" style={{textDecoration: 'none'}}>
                            <CardImg src="../media/LI-In-Bug.png" style={{height: "3rem", width:"3rem"}} className="align-self-center"></CardImg>
                            <CardText className="text-primary">LinkedIn</CardText>
                        </Link>
                    </Card>
                    <Card className="w-auto border-0 text-center">
                        <Link to="https://github.com/Wesdewess" target="_blank" style={{textDecoration: 'none'}}>
                            <CardImg src="../media/github-mark.png" style={{height: "3rem", width:"3rem"}} className="align-self-center"></CardImg>
                            <CardText className="text-primary">Github</CardText>
                        </Link>
                    </Card>
                </Row>
            </Container>

            <Container>
            <Row xs={1} md={2} className="g-2 p-3">
                <Col>
                <Link to={`/portfolio`} style={{ textDecoration: 'none' }}>
                        <Card className="border-primary bg-white h-100">
                            <Card.Header as="h1" className="text-primary">Portfolio</Card.Header>
                            <Card.Body>
                                <CardText>Bekijk hier verschillende projecten die ik heb gemaakt.</CardText>
                            </Card.Body>
                        </Card>
                    </Link>
                </Col>
                <Col>
                    <Link to={`/photography`} style={{ textDecoration: 'none' }}>
                        <Card className="border-primary bg-white h-100">
                            <Card.Header as="h1" className="text-primary">Fotografie</Card.Header>
                            <Card.Body className="align-content-center">
                                <CardText>Bekijk foto's waar ik trots op ben.</CardText>
                            </Card.Body>
                        </Card>
                    </Link>
                </Col>
            </Row>
            </Container>
        </Container>
    )
}

export default Home;